import "./GrailAdmin.css";

import { withAuthenticationRequired } from "@auth0/auth0-react";
import { Box, Button, Card, CardHeader, Stack, TextField } from "@mui/material";
import { Form, Formik, useField } from "formik";
import { useState } from "react";
import { useIntl } from "react-intl";
import { object, string } from "yup";

import { useLazyFetchOrderByIdQuery } from "../../slices/grailAdmin/grailAdmin.service";
import { Login } from "../Login/Login";

const GrailAdmin: React.FC = () => {
  const { formatMessage } = useIntl();
  const [grailOrderId, setGrailOrderId] = useState<string | undefined>("");
  const [fetchGrailOrder, result] =
  useLazyFetchOrderByIdQuery();
  const fetchOrderById = (values: any) => {
    if (values?.grailOrderId !== "") {
      setGrailOrderId(values.grailOrderId);
      fetchGrailOrder({grailOrderId: values.grailOrderId});
    }
  };
  return (
    <Stack
      spacing={5}
      sx={{
        alignItems: "center",
      }}
    >
      <Formik
        initialValues={{
          grailOrderId: grailOrderId,
        }}
        validationSchema={object({
          grailOrderId: string().required("*Required"),
        })}
        onSubmit={async (values, { setSubmitting }) => {
          fetchOrderById(values);
        }}
      >
        <Form className="formContainer">
          <TextFieldWrapper
            name="grailOrderId"
            label={formatMessage({ id: "grailAdmin.input.actions" })}
            type="text"
          ></TextFieldWrapper>
          <Button
            type="submit"
            variant="contained"
            className="submitButton"
          >
            Find Member
          </Button>
        </Form>
      </Formik>
      <Card className="resultsBox">
        <CardHeader
          title={formatMessage({ id: "grailAdmin.results.header" })}
          titleTypographyProps={{ component: "h1", variant: "subtitle1" }}
        />
        <Box className="resultsRow">
          VCA Member ID: {result.currentData?.vcaMemberId}
        </Box>
        <Box className="resultsRow">
          Date of Birth: {result.currentData?.dateOfBirth}
        </Box>
      </Card>
    </Stack>
  );
};

const TextFieldWrapper = (props: any) => {
  const [field, meta] = useField(props);
  const showError = meta.touched && meta.error;
  return (
    <Box className="textInputField">
      <TextField
        required
        {...field}
        {...props}
        placeholder="XXX-XXXX-XXX"
      />
      <Box
        className="error"
        sx={{ visibility: showError ? "visible" : "hidden" }}
      >
        {meta.error}
      </Box>
    </Box>
  );
};
export default withAuthenticationRequired(GrailAdmin, {
  onRedirecting: () => <Login />,
});
