import { MessageDescriptor } from "react-intl";

import { AccessKeys } from "../../config/pageAccess.config";
import Benefits from "../../pages/Benefits/Benefits";
import ClientView from "../../pages/Clients/ClientView";
import { ExactSciencesAdmin } from "../../pages/ExactSciencesAdmin/ExactSciencesAdmin";
import GrailAdmin from "../../pages/GrailAdmin/GrailAdmin";
import GroupView from "../../pages/Groups/GroupView";
import MemberMerge from "../../pages/MemberMerge/MemberMerge";
import PlanView from "../../pages/PlanView/PlanView";
import Questionnaire from "../../pages/Questionnaire/Questionnaire";
import CreateReport from "../../pages/Reports/CreateReport";
import SSOTool from "../../pages/SSOTool/SsoTool";

enum AppRoute {
  memberActions = "/memberActions",
  clientManagement = "/clientManagement",
  questionnaire = "/questionnaire",
  benefits = "/benefits",
  ssoTool = "/sso",
  createProductOffering = "/createProductOffering",
  planView = "/planView/:clientID/:groupID",
  report = "/report",
  groups = "/groups/:clientID",
  exactSciencesAdmin = "/labAdministration",
  grailAdmin = "grail/labAdministration",
}

type RouteDefinition = {
  path: string;
  accessKey: AccessKeys;
  featureFlagged: boolean;
  element: React.FC;
  tileGroup?: MessageDescriptor; // If internal route with no tile associated, leave undefined
  tileTitle?: MessageDescriptor; // If internal route with no tile associated, leave undefined
  tileDesc?: MessageDescriptor; // If internal route with no tile associated, leave undefined
};

const RouteDefinitions: RouteDefinition[] = [
  {
    path: AppRoute.benefits,
    accessKey: "benefits",
    element: Benefits,
    tileGroup: { id: "home.title" },
    tileTitle: { id: "home.benefits" },
    tileDesc: { id: "home.benefitsSubtitle" },
    featureFlagged: false,
  },
  {
    path: AppRoute.questionnaire,
    accessKey: "development",
    element: Questionnaire,
    tileGroup: { id: "home.title" },
    tileTitle: { id: "home.questionnaire" },
    tileDesc: { id: "home.questionnaireSubtitle" },
    featureFlagged: true,
  },
  {
    path: AppRoute.memberActions,
    accessKey: "memberMerge",
    element: MemberMerge,
    tileGroup: { id: "home.title" },
    tileTitle: { id: "home.memberActions" },
    tileDesc: { id: "home.memberActionsSubtitle" },
    featureFlagged: false,
  },
  {
    path: AppRoute.ssoTool,
    accessKey: "sso",
    element: SSOTool,
    tileGroup: { id: "home.developerTitle" },
    tileTitle: { id: "home.sso" },
    tileDesc: { id: "home.ssoSubtitle" },
    featureFlagged: true,
  },
  {
    path: AppRoute.clientManagement,
    accessKey: "clientManagement",
    element: ClientView,
    tileGroup: { id: "home.title" },
    tileTitle: { id: "home.clientManagement" },
    tileDesc: { id: "home.clientManagementSubtitle" },
    featureFlagged: false,
  },
  {
    path: AppRoute.planView,
    accessKey: "clientManagement",
    element: PlanView,
    featureFlagged: false,
  },
  {
    path: AppRoute.groups,
    accessKey: "clientManagement",
    element: GroupView,
    featureFlagged: false,
  },
  {
    path: AppRoute.report,
    accessKey: "reporting",
    element: CreateReport,
    tileGroup: { id: "home.reportingTile" },
    tileTitle: { id: "home.reportingTitle" },
    tileDesc: { id: "home.reportingSubtitle" },
    featureFlagged: true,
  },
  {
    path: AppRoute.exactSciencesAdmin,
    accessKey: "exasAdmin",
    element: ExactSciencesAdmin,
    tileGroup: { id: "home.reportingTile" },
    tileTitle: { id: "home.exactAdmin" },
    tileDesc: { id: "home.exactAdminSubtitle" },
    featureFlagged: false,
  },
  {
    path: AppRoute.grailAdmin,
    accessKey: "grailAdmin",
    element: GrailAdmin,
    tileGroup: { id: "home.reportingTile" },
    tileTitle: { id: "home.grailAdmin" },
    tileDesc: { id: "home.grailAdminSubtitle" },
    featureFlagged: false,
  },
];

export { AppRoute, RouteDefinitions };

export type { RouteDefinition };
