import { createSlice, Slice } from "@reduxjs/toolkit";

import { RootState } from "../../app/store";
import { grailAdminApi } from "./grailAdmin.service";

export type GrailAdminState = {
  grailOrderId: string;
  dateOfBirth: string;
  vcaMemberId: number;
};

const initialState: GrailAdminState = {
  grailOrderId: "",
  dateOfBirth: "",
  vcaMemberId: 0
};

export const grailAdminSlice: Slice<GrailAdminState> = createSlice({
  name: "grailAdmin",
  initialState,
  reducers: {
    setGrailOrderId: (state, action) => {
      state.grailOrderId = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      grailAdminApi.endpoints.fetchOrderById.matchFulfilled,
      (state, response: any) => {
        state.vcaMemberId = response.vcaMemberId;
        state.dateOfBirth = response.dateOfBirth;
      }
    );
  },
});

export const { setGrailOrderId } = grailAdminSlice.actions;

export default grailAdminSlice.reducer;
export const selectGrailPatientOrderId = (state: RootState) =>
  state.grailAdmin.grailOrderId;
