import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import { RootState } from "../../app/store";
import { PatientDataByOrderIdResponse } from "../../common/resources/grailAdmin.types";

export const grailAdminApi = createApi({
  reducerPath: "grailAdminApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_API_LAB_INTEGRATION_URL}`,
    prepareHeaders: (headers, { getState }) => {
      headers.set("Content-Type", "application/json");
      const state = getState() as RootState;
      if (state.user.accessToken) {
        headers.append("Authorization", `Bearer ${state.user.accessToken}`);
      }
      return headers;
    },
  }),
  tagTypes: ["GrailAdmin", "GrailOrderRecord"],
  endpoints: (builder) => ({
    fetchOrderById: builder.query<PatientDataByOrderIdResponse, { grailOrderId: string }>({
      query: (body) => ({
        url: `/admin/orders/${body.grailOrderId}`,
        method: "GET",
      }),
      providesTags: ["GrailOrderRecord"],
    }),
  }),
});

export const { useLazyFetchOrderByIdQuery } = grailAdminApi;
